const BUTTON_SELECTOR = '.darkMode';
const DARK_MODE_KEY = 'dark-mode';

/**
 * Button, that when clicked, toggles dark mode
 */
export function init() {
  let buttons = document.querySelectorAll(BUTTON_SELECTOR);

  // Set the initial state
  const initialDarkMode = localStorage.getItem(DARK_MODE_KEY) || false;
  buttons.forEach((button) => setButtonToggled(button, initialDarkMode));

  // Toggle on press
  buttons.forEach((button) =>
    button.addEventListener('click', (e) => {
      const oldState = localStorage.getItem(DARK_MODE_KEY);
      const newState =
        // If set and true, set to false
        // In other cases (undefined, false, nonsense) set to true
        oldState === 'true' ? 'false' : 'true';

      buttons.forEach((button) => setButtonToggled(button, newState));
      localStorage.setItem(DARK_MODE_KEY, newState);

      if (newState === 'true') {
        document.documentElement.classList.add('theme-dark');
        document.documentElement.classList.remove('theme-light');
      } else {
        document.documentElement.classList.add('theme-light');
        document.documentElement.classList.remove('theme-dark');
      }
    })
  );
}

function setButtonToggled(el, toggled) {
  el.setAttribute('aria-pressed', toggled);
}
